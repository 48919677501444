const matomo = () => {
  let _paq = (window._paq = window._paq || []);
  _paq.push(["setCustomUrl", location.pathname]);
  _paq.push(["setDocumentTitle", location.pathname + " - " + document.title]);
  _paq.push(["setCookieDomain", "*.express1040.com"]);
  _paq.push(["setDomains", ["*.express1040.com"]]);
  _paq.push(["enableCrossDomainLinking"]);
  _paq.push(["enableLinkTracking"]);
  (function () {
    let u = "https://analytics.express1040.com/";
    _paq.push(["setTrackerUrl", u + "matomo.php"]);
    _paq.push(["setSiteId", "3"]);
  })();
  _paq.push(["trackPageView"]);
};

export default matomo;
