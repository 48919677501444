import { store } from "@/functions/store";
import Prices from "../../data/default-prices.json";
import getPriceSlashData from "@/functions/getPriceSlashData";
import getBannerPriceConfigs from "@/functions/getBannerPriceConfigs";
import createUUID from "@/functions/createUUID";

const getPrices = async () => {
  let priceScheduleApi =
    "https://" +
    process.env.VUE_APP_API_SUBDOMAIN +
    "." +
    process.env.VUE_APP_SITENAME +
    ".com" +
    process.env.VUE_APP_MONOSERVICE_PATH +
    "/prices";
  const isTimeTravelTesting = process.env.VUE_APP_TIME_TRAVEL;
  if (
    isTimeTravelTesting === "true" &&
    location.href.includes("date=") &&
    location.href.includes("data=prod")
  ) {
    priceScheduleApi =
      "https://api." +
      process.env.VUE_APP_SITENAME +
      ".com" +
      process.env.VUE_APP_MONOSERVICE_PATH +
      "/prices";
  }
  let trace = createUUID();

  await fetch(priceScheduleApi, {
    method: "post",
    headers: {
      "content-type": "application/json",
      "X-Trace": trace,
    },
    body: JSON.stringify({
      appName: "express1040",
    }),
  })
    .then(async (response) => {
      store.prices = await response.json();
    })
    .catch(() => {
      store.prices = Prices.data;
    });

  store.prices = await getPriceSlashData(store.prices);
  store.prices = await getBannerPriceConfigs(store.prices);
};

export default getPrices;
